import type { Message } from "./message";

export interface AIChatProps {
  thread: Message[];
  prompt: string;
  promptInputPlaceholder?: string;
  chatTitle?: string;
  chatDescription?: string;
  isWaitingForResponse?: boolean;
  onPromptChange: (prompt: string) => void;
  onSubmit?: () => void;
  onApplyAssistantSuggestion?: (suggestion: string) => void;
  onAttachFiles?: (files: FileList) => void;
  onDeleteThread?: () => void;
  isThreadLoading: boolean;
  size?: keyof typeof CHAT_SIZE;
  threadId: string;
  queryId: string;
  isQueryLoading?: boolean;
}

export const CHAT_SIZE = {
  MEDIUM: "MEDIUM",
  LARGE: "LARGE",
  FIT_PAGE: "FIT_PAGE",
} as const;
