import {
  Flex,
  Sidebar,
  SidebarProvider,
  type SidebarState,
} from "@appsmith/wds";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getIsViewMode } from "selectors/editorSelectors";
import { useAIChatHeight } from "../hooks/useAIChatHeight";
import { ChatCitationEmpty } from "./ChatCitationEmpty";
import { ChatDocumentViewer } from "./ChatDocumentViewer";
import { ChatHeader } from "./ChatHeader";
import { ChatInputSection } from "./ChatInputSection";
import { ChatMessageList } from "./ChatMessageList";
import { useFetchDocument } from "./citationViewers/useFetchDocument";
import styles from "./styles.module.css";
import { type AIChatProps } from "./types";
import { useFetchCitation } from "./useFetchCitation";
import { ChatSkeleton } from "./ChatSkeleton";
import { ChatWebPageViewer } from "./citationViewers/ChatWebPageViewer";

export const AIChat = (props: AIChatProps) => {
  const {
    chatDescription,
    chatTitle,
    isQueryLoading,
    isThreadLoading,
    isWaitingForResponse = false,
    onApplyAssistantSuggestion,
    onAttachFiles,
    onDeleteThread: onDeleteThreadProp,
    onPromptChange,
    onSubmit,
    prompt,
    promptInputPlaceholder,
    queryId,
    size,
    thread,
    threadId,
  } = props;
  const { search } = window.location;
  const queryParams = new URLSearchParams(search);
  const isEmbed = queryParams.get("embed") === "true";
  const chatHeight = useAIChatHeight(size, isEmbed);
  const isDeleteThreadDisabled =
    thread.length === 1 || isThreadLoading || isWaitingForResponse;
  const [sidebarState, setSidebarState] = useState<SidebarState>("collapsed");
  const [selectedCitationId, setSelectedCitationId] = useState<string | null>(
    null,
  );
  const { citation: selectedMessageCitation, message: selectedMessage } =
    useMemo(() => {
      const message = thread.find((t) =>
        t.citations.find((c) => c.id === selectedCitationId),
      );
      const citation = message?.citations.find(
        (c) => c.id === selectedCitationId,
      );

      return { message, citation };
    }, [selectedCitationId, thread]);
  const viewMode = useSelector(getIsViewMode);

  const {
    citation: selectedCitation,
    hasError: hasCitationError,
    isLoading: isCitationLoading,
    retry: retryFetchCitation,
  } = useFetchCitation({
    queryId,
    citationId: selectedCitationId,
    messageId: selectedMessage?.id || null,
    messageCitation: selectedMessageCitation,
    threadId,
    viewMode,
  });

  const {
    document,
    hasError: isDocumentError,
    isLoading: isDocumentLoading,
    retry: retryFetchDocument,
  } = useFetchDocument(
    selectedCitation?.url,
    selectedCitation?.integrationType,
    selectedCitationId,
  );

  const onCitationOpen = useCallback(
    (citationId: string) => {
      setSelectedCitationId(citationId);
    },
    [setSelectedCitationId],
  );

  // along with deleting the thread, we also reset the selected citation when thread is deleted,
  // this is to ensure that the sidebar is resetted when the thread is deleted
  const onDeleteThread = useCallback(() => {
    onDeleteThreadProp?.();
    setSelectedCitationId(null);
  }, [onDeleteThreadProp]);

  return (
    <div
      className={styles.aiChat}
      style={
        {
          "--chat-height": chatHeight,
        } as React.CSSProperties
      }
    >
      {isQueryLoading && <ChatSkeleton />}
      {!isQueryLoading && (
        <>
          <ChatHeader
            description={chatDescription}
            isDeleteThreadDisabled={isDeleteThreadDisabled}
            onDeleteThread={onDeleteThread}
            setSidebarState={setSidebarState}
            sidebarState={sidebarState}
            title={chatTitle}
          />
          <SidebarProvider
            className={styles.sidebarWrapper}
            onStateChange={setSidebarState}
            side="end"
            state={sidebarState}
          >
            <Flex direction="column" width="100%">
              <ChatMessageList
                isThreadLoading={isThreadLoading}
                onApplyAssistantSuggestion={onApplyAssistantSuggestion}
                onCitationOpen={onCitationOpen}
                selectedCitationId={selectedCitationId}
                thread={thread}
              />
              <ChatInputSection
                isThreadLoading={isThreadLoading}
                isWaitingForResponse={isWaitingForResponse}
                onAttachFiles={onAttachFiles}
                onPromptChange={onPromptChange}
                onSubmit={onSubmit}
                prompt={prompt}
                promptInputPlaceholder={promptInputPlaceholder}
              />
            </Flex>
            <Sidebar title={selectedCitation?.name || ""}>
              {({ isAnimating }) => {
                if (!selectedCitationId) {
                  return <ChatCitationEmpty />;
                }

                if (
                  selectedCitationId &&
                  selectedMessageCitation?.integrationType === "WEB_SCRAPE"
                ) {
                  return <ChatWebPageViewer url={selectedCitation?.url} />;
                }

                if (selectedCitationId) {
                  return (
                    <ChatDocumentViewer
                      citation={selectedCitation}
                      document={document}
                      hasCitationError={hasCitationError}
                      hasDocumentError={isDocumentError}
                      isAnimating={isAnimating}
                      isLoading={isCitationLoading || isDocumentLoading}
                      retryCitation={retryFetchCitation}
                      retryDocument={retryFetchDocument}
                      selectedCitationId={selectedCitationId}
                    />
                  );
                }

                return null;
              }}
            </Sidebar>
          </SidebarProvider>
        </>
      )}
    </div>
  );
};
